import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

export enum IAlertType {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  DANGER = 'danger'
}

export interface IAlertConfig {
  clrAlertType: IAlertType;
  message: string;
  clrAlertAppLevel: boolean;
  clrAlertClosable: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class AlertService {
  shouldShowAlert = false;
  alertConfig: IAlertConfig = {
    clrAlertType: IAlertType.INFO,
    message: 'All engines running :)',
    clrAlertAppLevel: true,
    clrAlertClosable: true
  };

  private subject = new Subject<any>();
  private keepAfterRouteChange = false;

  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert message
          this.clear();
        }
      }
    });
  }

  show(config: IAlertConfig): AlertService {
    this.shouldShowAlert = true;
    this.alertConfig = {...this.alertConfig, ...config};
    return this;
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(message: string, keepAfterRouteChange = false): void {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({ type: 'success', text: message });

    this.alertConfig.message = 'All engines running 62 :)';
  }

  error(message: string, keepAfterRouteChange = false): void {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({ type: 'error', text: message });
  }

  clear(): void {
    // clear by calling subject.next() without parameters
    this.subject.next();
  }
}
