import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AlertService, IAlertType } from './alert.service';
import { Observable, throwError } from 'rxjs';
import { Member, WebserviceResponse } from '../interfaces';
import { catchError, retry } from 'rxjs/operators';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MemberStoreService {
  members: Member[] | undefined;
  // token: string | readonly string[];

  private IVY_API = 'https://ivyplus.wmedia.de:8185/rest/member';

  constructor(
    private http: HttpClient,
    private alertDispatcher: AlertService,
    // private tokenss: TokenStorageService
  ) {
    // this.token = this.tokenss.getToken();
    // if (this.token === null ) { this.token = ''; }
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Server-side error code: ${error.status} - message: ${error.message}`;
    }
    // window.alert(errorMessage);

    this.alertDispatcher.alertConfig.message = errorMessage;
    this.alertDispatcher.alertConfig.clrAlertType = IAlertType.DANGER;

    return throwError(errorMessage);
  }

  getAllMembers(clubid: string): Observable<WebserviceResponse<Member[]>> {
    const options = new HttpParams({
      fromObject: {
        club_id: clubid,
        person_id: '0'
      }
    });
    return this.http.get<WebserviceResponse<Member[]>>(this.IVY_API + '/get_member_by_club_id',
      { params: options }).pipe(retry(3), catchError(this.handleError));
  }

  getSingleMember(clubid: string, personID: string): Observable<WebserviceResponse<Member[]>> {
    const options = new HttpParams({
      fromObject: {
        club_id: clubid,
        person_id: personID
      }
    });
    return this.http.get<WebserviceResponse<Member[]>>(this.IVY_API + '/get_member_by_club_id',
      { params: options }).pipe(retry(3), catchError(this.handleError));
  }
}
