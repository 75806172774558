<div class="main-container">
  <app-alert></app-alert>
  <app-header></app-header>
  <div class="content-container">
    <div class="content-area">
      <h2>404 - Not found!</h2>
      <h5>Oops! Seite nicht gefunden!</h5>

      <div class="error-template">
        <div class="error-actions">
          <a routerLink="/dashboard/vvs" class="btn btn-danger">
            Zurück zur Startseite
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
