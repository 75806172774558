import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { INFORMATION_COMPONENTS , InformationRoutingModule} from './information-routing.module';
import { ClarityModule } from '@clr/angular';


@NgModule({
  declarations: [
    INFORMATION_COMPONENTS
  ],
  imports: [
    CommonModule,
    RouterModule,
    InformationRoutingModule,
    ClarityModule
  ],
  exports: [
    INFORMATION_COMPONENTS,
  ]
})
export class InformationModule { }
