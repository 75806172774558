import { Component, OnInit } from '@angular/core';
import { Member } from '../../../../_shared/interfaces';
import { ClrDatagridSortOrder } from '@clr/angular';
import { MemberStoreService } from '../../../../_shared/services/member-store.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-mitglied-details',
  templateUrl: './mitglied-details.component.html',
  styleUrls: ['./mitglied-details.component.sass']
})
export class MitgliedDetailsComponent implements OnInit {
  member: Member = {
    id: 0,
    id_person: 0,
    firstName: '',
    lastName: '',
    place_of_birth: '',
    street: '',
    plz: '',
    ort: ''
  };
  memberid: string | null = '';
  descSort: ClrDatagridSortOrder;
  loading = true;

  constructor(
    private mss: MemberStoreService,
    private route: ActivatedRoute
  ) {
    this.loading = false;
    this.descSort = ClrDatagridSortOrder.ASC;
  }

  ngOnInit(): void {
    const params = this.route.snapshot.paramMap;
    this.loading = true;
    this.memberid = params.get('memberid');
    this.loadMemberCard(this.memberid);
  }

  loadMemberCard(memberID: string | null) {
    if (memberID) {
      this.mss.getSingleMember('3118', memberID)
        .subscribe(data => {
          if (!data) {
            return;
          }
          this.member = data.object[0];
          this.loading = false;
          this.descSort = ClrDatagridSortOrder.ASC;
        });
    }
  }

  calAge(birthday: Date | undefined): number {
    let diff = moment(birthday).diff(moment(), 'milliseconds');
    let duration = moment.duration(diff);
    let age = duration.years();
    return Math.abs(age);
  }
}
