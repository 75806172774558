import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VvsComponent } from './vvs/vvs.component';
import { AuthGuard } from '../../_shared/helpers/auth.guard';
import { BalanceComponent } from './balance/balance.component';
import { SupportComponent } from './support/support.component';
import { ApiComponent } from './api/api.component';

export const DASHBOARD_ROUTES: Routes = [
  {
    path: 'dashboard',
    pathMatch: 'full',
    redirectTo: 'dashboard/vvs',
  },
  {
    path: 'dashboard/vvs',
    component: VvsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/balance',
    component: BalanceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/support',
    component: SupportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/api',
    component: ApiComponent,
    canActivate: [AuthGuard]
  }
];

export const DASHBOARD_COMPONENTS = [
  VvsComponent,
  BalanceComponent,
  SupportComponent,
  ApiComponent
];

@NgModule({
  imports: [RouterModule.forChild(DASHBOARD_ROUTES)],
  exports: [RouterModule],
  entryComponents: [],
})
export class DashboardRoutingModule { }
