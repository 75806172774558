import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {DataStoreService} from '../../../_shared/services/data-store.service';
import {Club} from '../../../_shared/interfaces';

@Component({
  selector: 'app-stammdaten',
  templateUrl: './stammdaten.component.html',
  styleUrls: ['./stammdaten.component.sass']
})
export class StammdatenComponent implements OnInit {
  club: Club[] = [];
  clubID = '';
  loading = false;

  constructor(
    private datass: DataStoreService
  ) {
    // if (localStorage.getItem('ivy_user_id')) { this.clubID = localStorage.getItem('ivy_user_id').toString() }
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadClubData('3118');
  }

  loadClubData(clubID: string): void {
    this.datass.getClubData('3118')
      .subscribe(data => {
        if (!data) {
          return;
        }
        this.club = data.object;
        this.loading = false;
      });
  }

  calAge(birthday: Date | undefined): number {
    let diff = moment(birthday).diff(moment(), 'milliseconds');
    let duration = moment.duration(diff);
    let age = duration.years();
    return Math.abs(age);
  }

}
