import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AlertService, IAlertType} from './alert.service';
import {Club, Member, WebserviceResponse} from '../interfaces';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {

  private IVY_API = '/management/';

  constructor(
    private http: HttpClient,
    private alertDispatcher: AlertService
  ) { }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Server-side error code: ${error.status} - message: ${error.message}`;
    }
    // window.alert(errorMessage);

    this.alertDispatcher.alertConfig.message = errorMessage;
    this.alertDispatcher.alertConfig.clrAlertType = IAlertType.DANGER;

    return throwError(errorMessage);
  }

  getClubData(clubid: string): Observable<WebserviceResponse<Club[]>> {
    const options = new HttpParams({
      fromObject: {
        id: clubid
      }
    });
    return this.http.get<WebserviceResponse<Club[]>>(this.IVY_API + 'verein',
      { params: options }).pipe(retry(3), catchError(this.handleError));
  }
}
