<clr-main-container>
  <app-alert></app-alert>
  <app-header></app-header>
  <!--<app-main></app-main>-->
  <div class="content-container">
    <app-sidenav></app-sidenav>
    <!--<app-content></app-content>-->
    <div class="content-area">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</clr-main-container>
