import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FinanzamtComponent } from './finanzamt/finanzamt.component';
import { StammdatenComponent } from './stammdaten/stammdaten.component';
import { TrainerComponent } from './trainer/trainer.component';
import { MittelverwendungComponent } from './mittelverwendung/mittelverwendung.component';
import { PersonenComponent } from './personen/personen.component';
import { MitgliederComponent } from './mitglieder/mitglieder.component';
import { MitgliedDetailsComponent } from './mitglieder/mitglied-details/mitglied-details.component';
import { SubventionenComponent } from './subventionen/subventionen.component';
import { DokumenteComponent } from './dokumente/dokumente.component';
import { SportatlasComponent } from './sportatlas/sportatlas.component';
import { AuthGuard } from '../../_shared/helpers/auth.guard';

export const CLUB_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'club/stammdaten',
  },
  {
    path: 'club/stammdaten',
    component: StammdatenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club/personen',
    component: PersonenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club/mitglieder',
    component: MitgliederComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club/mitglieder/mitglied-details/:memberid',
    component: MitgliedDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club/trainer',
    component: TrainerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club/finanzamt',
    component: FinanzamtComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club/mittelverwendung',
    component: MittelverwendungComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club/subventionen',
    component: SubventionenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club/sportatlas',
    component: SportatlasComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club/dokumente',
    component: DokumenteComponent,
    canActivate: [AuthGuard]
  }
];

export const CLUB_COMPONENTS = [
  StammdatenComponent,
  PersonenComponent,
  MitgliederComponent,
  MitgliedDetailsComponent,
  TrainerComponent,
  FinanzamtComponent,
  MittelverwendungComponent,
  SubventionenComponent,
  SportatlasComponent,
  DokumenteComponent
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(CLUB_ROUTES)
  ],
  exports: [RouterModule]
})
export class ClubRoutingModule { }

