import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../_shared/services';
import PackageJson from '../../../package.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})

export class LoginComponent implements OnInit {
  loginForm = this.formbuilder.group({
    username: new FormControl(''),
    password: new FormControl('')
  });
  errorStatus = false;
  loading = false;
  private authService: AuthService;
  private username = '';
  private password = '';

  public ivyversion: string = PackageJson.version;

  constructor(
    private formbuilder: FormBuilder,
    authService: AuthService,
  ) {
    this.authService = authService;
  }

  ngOnInit(): void {}

  loginUser(): void {
    const formValues = this.loginForm.value;
    this.authService.signIn(formValues.username, formValues.password);
    this.loading = true;
    this.errorStatus = this.authService.success;
  }
}
