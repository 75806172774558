import { Component, OnInit } from '@angular/core';
import {AuthService, TokenStorageService} from '../../../_shared/services';

@Component({
  selector: 'app-trainer',
  templateUrl: './trainer.component.html',
  styleUrls: ['./trainer.component.sass']
})
export class TrainerComponent implements OnInit {
  token: string | readonly string[];
  isValid = false;

  constructor(
    private tokenss: TokenStorageService,
    private authss: AuthService
  ) {
    this.token = this.tokenss.getToken();
    this.isValid = false;
  }

  ngOnInit(): void {
    this.checkPing();
  }

  checkPing(): any {
    // alert('ping is checking!');
    // this.isValid = this.authss.ping();
    console.log('28 - ping is ' + this.authss.ping());
  }

}
