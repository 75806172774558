import { Component, OnInit } from '@angular/core';
import { MemberStoreService } from '../../../_shared/services/member-store.service';
import { ClrDatagridSortOrder } from '@clr/angular';
import { Member, WebserviceResponse } from '../../../_shared/interfaces';
import * as moment from 'moment';

@Component({
  selector: 'app-mitglieder',
  templateUrl: './mitglieder.component.html',
  styleUrls: ['./mitglieder.component.sass']
})
export class MitgliederComponent implements OnInit {
  members: Member[] = [];
  descSort;
  loading: boolean;
  clubID: string | null = localStorage.getItem('ivy_club_id');

  constructor(
    private mss: MemberStoreService
  ) {
    this.loading = false;
    this.descSort = ClrDatagridSortOrder.ASC;
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadMemberList();
  }

  loadMemberList(): void {
    this.mss.getAllMembers('3118')
      .subscribe(data => {
        if (!data) {
          return;
        }
        this.members = data.object;
        this.loading = false;
        this.descSort = ClrDatagridSortOrder.ASC;
      });
  }

  calAge(birthday: Date | undefined): number {
    let diff = moment(birthday).diff(moment(), 'milliseconds');
    let duration = moment.duration(diff);
    let age = duration.years();
    return Math.abs(age);
  }
}
