<div class="login-wrapper">
  <form class="login" [formGroup]="loginForm">
    <section class="title">
      <span class="p7">V  {{ ivyversion }}</span>
      <h3 class="welcome">Willkommen im</h3>
      <div class="branding">
        <img class="ivy_logo" src="../../../assets/images/bildmarke-1.png" alt="IVYPlus" />
      </div>
      <h5 class="hint">Bitte nutzen Sie Ihren IVY-Zugang,<br />um sich anzumelden.</h5>
    </section>
    <div class="login-group">
      <clr-input-container>
        <label class="clr-sr-only">Benutzername</label>
        <input type="text" name="username" class="form-control" formControlName="username" clrInput placeholder="Benutzername" required />
        <clr-control-error>Das Feld darf nicht leer bleiben.</clr-control-error>
      </clr-input-container>
      <clr-password-container>
        <label class="clr-sr-only">Passwort</label>
        <input type="password" name="password" class="form-control" formControlName="password" clrPassword placeholder="Passwort" required />
        <clr-control-error>Das Feld darf nicht leer bleiben.</clr-control-error>
      </clr-password-container>
      <div *ngIf="errorStatus === true && !loading" class="error active">
        Invalid user name or password
      </div>
      <button *ngIf="!loading" (click)="loginUser()" class="btn btn-primary p7">Anmelden</button>
      <button *ngIf="loading" class="btn btn-primary p7">
        <clr-spinner clrInline> Moment...</clr-spinner>
        <span> Moment...</span>
      </button>
    </div>
  </form>
</div>
