<header class="header header-2">
  <div class="branding">
    <span class="title">120061 - RUDERCLUB ALT-WERDER MAGDEBURG 1887 e.V.</span>
  </div>
</header>
<nav class="subnav" [clr-nav-level]="2">
  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link active" [routerLink]="['/club/mitglieder']">Übersicht</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">neue Mitglieder</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">ausscheidende Mitglieder</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#">Archiv</a>
    </li>
  </ul>
</nav>
<main>

  <h2>Verein <clr-icon shape="two-way-arrows"></clr-icon> Mitgliederverwaltung</h2>
  <a class="btn btn-primary btn-sm" [routerLink]="['/club/mitglieder']">Neues Mitglied</a>

  <clr-datagrid [clrDgLoading]="loading">>
    <clr-dg-column>LSB-ID</clr-dg-column>
    <clr-dg-column> </clr-dg-column>
    <clr-dg-column [clrDgField]="'firstName'">Vorname</clr-dg-column>
    <clr-dg-column [clrDgField]="'lastName'" [clrDgSortOrder]="descSort">Nachname</clr-dg-column>
    <clr-dg-column [clrDgField]="'date_of_birth'">Geburtsdatum</clr-dg-column>
    <clr-dg-column>Alter</clr-dg-column>
    <clr-dg-column>Straße</clr-dg-column>
    <clr-dg-column>PLZ</clr-dg-column>
    <clr-dg-column>Ort</clr-dg-column>

    <clr-dg-row *clrDgItems="let member of members" [routerLink]="['/club/mitglieder/mitglied-details', member.id_person ]">
      <clr-dg-cell>{{ member.id_person }}</clr-dg-cell>
      <clr-dg-cell><clr-icon shape="assign-user" class="is-solid"></clr-icon></clr-dg-cell>
      <clr-dg-cell>{{ member.firstName }}</clr-dg-cell>
      <clr-dg-cell>{{ member.lastName }}</clr-dg-cell>
      <clr-dg-cell>{{ member.date_of_birth | date: 'dd.MM.yyyy' }}</clr-dg-cell>
      <clr-dg-cell>{{ calAge(member.date_of_birth) }}</clr-dg-cell>
      <clr-dg-cell class="nowrap">{{ member.street }}</clr-dg-cell>
      <clr-dg-cell>{{ member.plz }}</clr-dg-cell>
      <clr-dg-cell>{{ member.ort }}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="20">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Mitglieder pro Seite</clr-dg-page-size>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
        von  {{ members.length }} Mitgliedern
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</main>
