import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.authService.ping();
    console.log('21- auth check: ' + this.authService.checker);
    return this.authService.checker
      ? true
      : this.router.parseUrl('/login');
    // : this.router.parseUrl('/jobs');
    // : this.router.parseUrl('/notification/details/1179.0004/1');
    // : this.router.parseUrl('/jobs/details/1179.0004/2');
    // return true;
  }
}
