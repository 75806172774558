<nav class="subnav">
  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link active" [routerLink]="['/club/mitglieder']">Zur Übersicht</a>
    </li>
  </ul>
</nav>

<main>
  <h2>PHM PPS  <clr-icon shape="two-way-arrows"></clr-icon>  Personendetails <clr-icon shape="two-way-arrows"></clr-icon> </h2>
  <div class="clr-row" >
    <div class="clr-col-6">
      <div *ngIf="!!member" class="card">
        <div class="card-header">
          Person <clr-icon shape="two-way-arrows"></clr-icon>  {{ member.firstName }} {{ member.lastName }}
        </div>
        <div class="card-block">
          <div class="card-text">
            <table class="table table-vertical">
              <tbody>
              <tr>
                <th>LSB-ID</th>
                <td>{{ member.id_person }}</td>
              </tr>
              <tr>
                <th>Vorname</th>
                <td>{{ member.firstName }}</td>
              </tr>
              <tr>
                <th>Nachname</th>
                <td>{{ member.lastName }}</td>
              </tr>
              <tr>
                <th>Geburtstag</th>
                <td>{{ member.date_of_birth | date:'dd.MM.yyyy'  }} (Alter: {{ calAge(member.date_of_birth)}})</td>
              </tr>
              <tr>
                <th>Adresse</th>
                <td>{{ member.street }}<br>{{ member.plz }} {{ member.ort }}</td>
              </tr>
              <tr>
                <th>Bundesland</th>
                <td> </td>
              </tr>
              <tr>
                <th>Telefon</th>
                <td nowrap> </td>
              </tr>
              <tr>
                <th>Mobile</th>
                <td> </td>
              </tr>
              <tr>
                <th>E-Mail</th>
                <td> </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-sm btn-link" [routerLink]="['/personen/update', member.id ]">Bearbeiten</button>
          <button class="btn btn-sm btn-link" >Löschen</button>
        </div>
      </div>
    </div>
  </div>
</main>
