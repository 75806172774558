import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertService, IAlertType } from './alert.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  endpoint = '/activity/';
  checker = false;
  success = false;

  constructor(
    private http: HttpClient,
    public router: Router,
    public alertDispatcher: AlertService
  ) {}

  // Sign-in
  async signIn(username: string, userpass: string): Promise<void> {
    const options = new HttpParams({
      fromObject: {
        namemail: username,
        pass: userpass
      }
    });
    await this.http.get<any>(this.endpoint + 'login?namemail=' + username + '&pass=' + userpass )
      .subscribe((res: any) => {

        if (res.hasErrors === false && res.hasErrors != null) {

          console.log('Resultat: ' + res.object.token + ' ' + res.object.softwareList[0].customValueList[0]);
          console.log(res.object.softwareList[0].customValueList[2].value);
          console.table(res.object.softwareList[0].customValueList[0]);
          this.checker = true;
          localStorage.setItem('ivy_access_token', res.object.token);
          localStorage.setItem('ivy_user_id', res.object.userId);
          localStorage.setItem('ivy_user_institution', this.splitLoginUser(res.object.softwareList[0].customValueList[2].value, 0));
          localStorage.setItem('ivy_user_name', this.splitLoginUser(res.object.softwareList[0].customValueList[2].value, 1));

          this.success = true;
          this.router.navigate(['/dashboard/vvs']);

        } else {
          this.success = false;
          this.router.navigate(['/login']);
        }
      });
    }


  // Ping REST-API
  ping(): void {
    this.http.get<any>(this.endpoint + 'active')
      .subscribe((res: any) => {
        if (res.hasErrors === false) {
          console.log('ping successful!' + ' hasErrors=' + res.hasErrors);

          this.alertDispatcher.alertConfig.message = 'All engines running! :) ';
          this.alertDispatcher.alertConfig.clrAlertType = IAlertType.SUCCESS;

          /*console.log(res);*/
          this.checker = true;
          // return res.hasErrors;
        } else {
          console.log('ping failed!');

          this.alertDispatcher.alertConfig.message = 'Ping failed! :( ';
          this.alertDispatcher.alertConfig.clrAlertType = IAlertType.DANGER;

          console.log(res.hasErrors);
          this.doLogout();
          this.checker = false;
          this.router.navigate(['/login']);
        }
      });
  }

  getToken(): string | null {
    return localStorage.getItem('ivy_access_token');
  }

  doLogout(): void {
    const logoutToken = localStorage.getItem('ivy_access_token');
    localStorage.removeItem('ivy_access_token');
    localStorage.removeItem('ivy_user_id');
    localStorage.removeItem('ivy_user_institution');
    localStorage.removeItem('ivy_user_name');
    this.http.get<any>(this.endpoint + 'logout')
      .subscribe((res: any) => {
        if (res.hasErrors === false) {
          this.alertDispatcher.alertConfig.message = 'Logout successful! :) ';
          this.alertDispatcher.alertConfig.clrAlertType = IAlertType.SUCCESS;

          this.router.navigate(['/login']);
        } else {
          this.alertDispatcher.alertConfig.message = 'Logout failed! :( ';
          this.alertDispatcher.alertConfig.clrAlertType = IAlertType.DANGER;

          this.router.navigate(['/login']);
        }
      });
  }


  // Error
  handleError(error: HttpErrorResponse): Observable<never> {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;

      this.alertDispatcher.alertConfig.message = msg;
      this.alertDispatcher.alertConfig.clrAlertType = IAlertType.DANGER;

    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;

      this.alertDispatcher.alertConfig.message = msg;
      this.alertDispatcher.alertConfig.clrAlertType = IAlertType.DANGER;
    }
    return throwError(msg);
  }

  splitLoginUser(username: string, substring: number): string {
    let splits = [];
    splits = username.split(', ');
    return splits[substring];
  }
}
