import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { AuthService } from '../services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    // private tokenisedRequest: HttpRequest<any>,
    private http: HttpClient
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authToken = this.authService.getToken();
    const checker = this.authService.checker;

    if (authToken == null) {
      authToken = '';
    }

    const tokenisedRequest = request.clone(
      {
        params: request.params.append('token', authToken),
      }
    );

    return next.handle(tokenisedRequest).
    pipe(
      tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
            console.log('36 - Interceptor HttpResponse ... do anything ' + authToken + ' ' + checker);
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // redirect to the login route
              // or show a modal
              console.log('43 - Interceptor HttpResponse Error 401 ... do anything ');
              // this.authService.doLogout();
            }
          }
        }
      ));
  }
}
