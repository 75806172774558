import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CLUB_COMPONENTS , ClubRoutingModule} from './club-routing.module';
import {ClarityModule} from '@clr/angular';



@NgModule({
  declarations: [
    CLUB_COMPONENTS
  ],
  imports: [
    CommonModule,
    RouterModule,
    ClubRoutingModule,
    ClarityModule
  ],
  exports: [
    CLUB_COMPONENTS,
  ]
})
export class ClubModule { }
