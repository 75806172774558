<div class="clr-footer">
  <div class="alert alert-app-level alert-warning">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-text"> Made with passion! </div>
      </div>
    </div>
  </div>
</div>

