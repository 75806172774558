import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../_shared/helpers/auth.guard';
import { KsbssbComponent } from './ksbssb/ksbssb.component';
import { AssociationComponent } from './association/association.component';
import { NewsComponent } from './news/news.component';
import { EducationComponent } from './education/education.component';

export const INFORMATION_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'information/ksbssb',
  },
  {
    path: 'information/ksbssb',
    component: KsbssbComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'information/association',
    component: AssociationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'information/news',
    component: NewsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'information/education',
    component: EducationComponent,
    canActivate: [AuthGuard]
  },
];

export const INFORMATION_COMPONENTS = [
  KsbssbComponent,
  AssociationComponent,
  NewsComponent,
  EducationComponent
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(INFORMATION_ROUTES)
  ],
  exports: [RouterModule]
})
export class InformationRoutingModule { }

