<clr-header class="header-1">
  <div class="branding">
    <a href="/" class="nav-link">
      <img class="ivy_logo" src="../../../assets/images/bildmarke-1.png" alt="IVYPlus" />
      <span class="title">Vereinsverwaltung</span><span class="p7">V {{ ivyversion }}</span>
    </a>
  </div>

  <form class="search">
    <label for="search_input">
      <input id="search_input" type="text" placeholder="Search for keywords... ">
    </label>
  </form>
  <div class="header-actions">
    <a (click)=logoutUser() class="nav-link nav-icon-text">
      <clr-icon shape="user"></clr-icon>
      <span class="nav-text">{{ userName }}</span>
    </a>
    <clr-dropdown class="bottom-left">
      <button class="nav-icon dropdown-toggle" clrDropdownTrigger aria-label="toggle settings menu">
        <clr-icon shape="cog"></clr-icon>
        <clr-icon shape="angle" direction="down"></clr-icon>
      </button>
      <div class="dropdown-menu">
        <a href="..." class="dropdown-item">  Über IVY+</a>
        <a href="..." class="dropdown-item">  Konfiguration</a>
        <a (click)=logoutUser()  class="dropdown-item">  Abmelden</a>
      </div>
    </clr-dropdown>
  </div>
</clr-header>
