import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_shared/services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.sass']
})
export class AlertComponent implements OnInit {
  public alertIcon = 'info-circle';
  public alertMessage = 'App Level Alert';
  public alertColor = '{ alert-info }';

  constructor(
    public alertService: AlertService
  ) { }

  ngOnInit(): void {
  }

}
