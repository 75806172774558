<clr-vertical-nav [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed" class="nav-trigger--bottom" [clr-nav-level]="1">
  <a clrVerticalNavLink routerLink="/dashboard/vvs" routerLinkActive="active">
    <clr-icon clrVerticalNavIcon shape="home"></clr-icon>
    Home
  </a>
  <div class="nav-divider"></div>
  <clr-vertical-nav-group
    routerLinkActive="active" [clrVerticalNavGroupExpanded]="true">
    <clr-icon shape="info-circle" clrVerticalNavIcon></clr-icon>
    Information
    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink routerLink="/information/ksbssb" routerLinkActive="active"> Mein KSB/SSB</a>
      <a clrVerticalNavLink routerLink="/information/association" routerLinkActive="active"> Meine Fachverbände</a>
      <a clrVerticalNavLink routerLink="/information/news" routerLinkActive="active"> News</a>
      <a clrVerticalNavLink routerLink="/information/education" routerLinkActive="active"> Bildung</a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>
  <div class="nav-divider"></div>
  <clr-vertical-nav-group
    routerLinkActive="active" [clrVerticalNavGroupExpanded]="true">
    <clr-icon shape="user" clrVerticalNavIcon></clr-icon>
    Verein
    <clr-vertical-nav-group-children>
      <a clrVerticalNavLink routerLink="/club/stammdaten" routerLinkActive="active"> Stammdaten</a>
      <a clrVerticalNavLink routerLink="/club/personen" routerLinkActive="active"> Personen</a>
      <a clrVerticalNavLink routerLink="/club/mitglieder" routerLinkActive="active">Mitgliederverwaltung</a>
      <a clrVerticalNavLink routerLink="/club/trainer" routerLinkActive="active">Übungsleiter</a>
      <a clrVerticalNavLink routerLink="/club/finanzamt" routerLinkActive="active">Finanzamt</a>
      <a clrVerticalNavLink routerLink="/club/mittelverwendung" routerLinkActive="active">Mittelverwendung</a>
      <a clrVerticalNavLink routerLink="/club/subventionen" routerLinkActive="active">Förderungen</a>
      <a clrVerticalNavLink routerLink="/club/sportatlas" routerLinkActive="active">Sportatlas</a>
      <a clrVerticalNavLink routerLink="/club/dokumente" routerLinkActive="active">Dokumente</a>
    </clr-vertical-nav-group-children>
  </clr-vertical-nav-group>
  <div class="nav-divider"></div>
  <a clrVerticalNavLink routerLink="/dashboard/balance" routerLinkActive="active">
    <clr-icon clrVerticalNavIcon shape="savings"></clr-icon>
    Bilanzen
  </a>
  <div class="nav-divider"></div>
  <a clrVerticalNavLink routerLink="/dashboard/support" routerLinkActive="active">
    <clr-icon clrVerticalNavIcon shape="envelope"></clr-icon>
    Support
  </a>
  <div class="nav-divider"></div>
  <a clrVerticalNavLink routerLink="/dashboard/api" routerLinkActive="active">
    <clr-icon clrVerticalNavIcon shape="data-cluster"></clr-icon>
    API
  </a>
</clr-vertical-nav>
