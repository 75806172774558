import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personen',
  templateUrl: './personen.component.html',
  styleUrls: ['./personen.component.sass']
})
export class PersonenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
