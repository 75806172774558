import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeDe);

import { AppComponent } from './app.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { Page404Component } from './page404/page404.component';
import { LoginComponent } from './login/login.component';
import { CoreComponent } from './core/core.component';
import { AlertComponent } from './core/alert/alert.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { SidenavComponent } from './core/sidenav/sidenav.component';
import { AlertService } from './_shared/services';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { ClubModule } from './pages/club/club.module';
import { InformationModule } from './pages/information/information.module';
import { AuthInterceptor } from './_shared/helpers';

@NgModule({
  declarations: [
    AppComponent,
    Page404Component,
    LoginComponent,
    CoreComponent,
    AlertComponent,
    HeaderComponent,
    FooterComponent,
    SidenavComponent,
  ],
  imports: [
    BrowserModule,
    ClarityModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    DashboardModule,
    ClubModule,
    InformationModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
    provide: LOCALE_ID, useValue: 'de'
  },
    AlertService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
