import { Component, OnInit } from '@angular/core';
import { AuthService, TokenStorageService } from '../../_shared/services';

import { version } from '../../../../package.json';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  public userInstitution: string | null;
  public userName: string | null;
  isLoggedIn = false;

  public ivyversion: string = version;

  constructor(
    public authService: AuthService
    /*private tokenStorageService: TokenStorageService,
    private router: Router*/
  ) {
    /*if (this.authService.isLoggedIn) {
      this.userName = localStorage.getItem('ivy_rbacuser_name');
      this.userID = localStorage.getItem('ivy_club_id');
    } else {
      this.userName = '';
      this.userID = null;
    }*/
    this.userInstitution = '';
    this.userName = '';
  }

  ngOnInit(): void {
    this.userInstitution = localStorage.getItem('ivy_user_institution');
    this.userName = localStorage.getItem('ivy_user_name');
    this.isLoggedIn = this.authService.checker;
    /*this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      this.userName = this.tokenStorageService.getUser();
      this.userID = 'TBD';
    }*/
  }

  logoutUser(): void {
    this.authService.doLogout();
    /*this.tokenStorageService.signOut();
    this.router.navigate(['/login']);*/
  }
}
